<script setup lang="ts">
import {
  FwbAvatar
} from 'flowbite-vue'
import logo from "@/assets/logo.png"
import {useRoute, useRouter} from "vue-router";
import {useAuthStore} from "@/stores/auth";
import {computed, onMounted} from 'vue'
import {initFlowbite} from 'flowbite'
import * as md5 from 'md5';

const route = useRoute()
const router = useRouter()
const authStore = useAuthStore()

const isActiveRoute = (name: string): boolean => {
  return name === route.name
}

onMounted(() => {
  initFlowbite();
})

const logout = () => {
  authStore.logout()
      .then(() => {
        router.push({name: 'login'})
        window.location.reload()
      })
}

const avatarUrl = computed(() => {
  if (authStore.payload && authStore.payload.username) {
    return md5(authStore.payload.username)
  } else {
    return ''
  }
})
</script>

<template>
  <nav class="bg-white border-gray-200 dark:bg-gray-900 mb-10">
    <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
      <a href="/" class="flex items-center">
        <img :src="logo" class="h-8 mr-3" alt="Globaltec"/>
        <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white" style="color: #67feeb">Globaltec</span>
      </a>
      <div class="flex items-center md:order-2">
        <button type="button"
                class="flex mr-3 text-sm bg-gray-800 rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
                id="user-menu-button" aria-expanded="false" data-dropdown-toggle="user-dropdown"
                data-dropdown-placement="bottom" v-if="authStore.isLoggedIn()">
          <span class="sr-only">Open user menu</span>
          <fwb-avatar :img="avatarUrl" rounded/>
        </button>
        <!-- Dropdown menu -->
        <div
            class="z-50 hidden my-4 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600"
            id="user-dropdown">
          <div class="px-4 py-3" v-if="authStore.payload">
            <span class="block text-sm text-gray-900 dark:text-white">{{ authStore.payload.username }}</span>
          </div>
          <ul class="py-2" aria-labelledby="user-menu-button">
            <li class="cursor-pointer">
              <a @click="logout"
                 class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Logout</a>
            </li>
          </ul>
        </div>
        <button data-collapse-toggle="navbar-user" type="button" v-if="authStore.isLoggedIn()"
                class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                aria-controls="navbar-user" aria-expanded="false">
          <span class="sr-only">Open main menu</span>
          <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M1 1h15M1 7h15M1 13h15"/>
          </svg>
        </button>
      </div>
      <div class="items-center justify-between hidden w-full md:flex md:w-auto md:order-1" id="navbar-user"
           v-if="authStore.isLoggedIn()">
        <ul class="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
          <li>
            <RouterLink :to="{name:'home'}" :aria-current="isActiveRoute('home') ? 'page' : null"
                        class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">
              Home
            </RouterLink>
          </li>
          <li>
            <RouterLink :to="{name:'clienti'}" :aria-current="isActiveRoute('clienti') ? 'page' : null"
                        class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">
              Clienti
            </RouterLink>
          </li>
        </ul>
      </div>
    </div>
  </nav>

</template>