<script setup lang="ts">
import {
  FwbA,
  FwbTable,
  FwbTableBody,
  FwbTableCell,
  FwbTableHead,
  FwbTableHeadCell,
  FwbTableRow,
} from 'flowbite-vue'
import {formatDate} from "@/utils";
import type { Impianto } from '@/interfaces/impianto'

const props = defineProps<{
  title: string,
  impianti: Impianto[]
}>()
</script>

<template>
  <div>
    <h1 class="text-4xl font-bold dark:text-white mb-6">{{ props.title }}</h1>

    <fwb-table striped>
      <fwb-table-head>
        <fwb-table-head-cell>Proponente</fwb-table-head-cell>
        <fwb-table-head-cell>Codice fiscale</fwb-table-head-cell>
        <fwb-table-head-cell>Partita IVA</fwb-table-head-cell>
        <fwb-table-head-cell>Indirizzo</fwb-table-head-cell>
        <fwb-table-head-cell>Comune</fwb-table-head-cell>
        <fwb-table-head-cell>Provincia</fwb-table-head-cell>
        <fwb-table-head-cell>Scadenza Assistenza</fwb-table-head-cell>
        <fwb-table-head-cell>
          <span class="sr-only">Dettaglio</span>
        </fwb-table-head-cell>
      </fwb-table-head>
      <fwb-table-body>
        <fwb-table-row v-for="impianto in props.impianti" :key="impianto.id">
          <fwb-table-cell>{{ impianto.cliente.proponente }}</fwb-table-cell>
          <fwb-table-cell>{{ impianto.cliente.fiscalCode }}</fwb-table-cell>
          <fwb-table-cell>{{ impianto.cliente.vatNumber }}</fwb-table-cell>
          <fwb-table-cell>{{ impianto.impiantoAddress }}</fwb-table-cell>
          <fwb-table-cell>{{ impianto.impiantoCity }}</fwb-table-cell>
          <fwb-table-cell>{{ impianto.impiantoProvince }}</fwb-table-cell>
          <fwb-table-cell>{{ impianto.scadenzaAssistenza instanceof Date ? formatDate(impianto.scadenzaAssistenza) : '' }}</fwb-table-cell>
          <fwb-table-cell>
            <fwb-a :href="'/impianto/detail/' + impianto.uuid">
              Dettaglio
            </fwb-a>
          </fwb-table-cell>
        </fwb-table-row>
      </fwb-table-body>
    </fwb-table>
  </div>
</template>
