import { defineStore } from 'pinia'
import { ref } from 'vue'
import type { Ref } from 'vue'
import { axiosRequest } from '@/api/api'
import type { AxiosResponse } from 'axios'
import type { HomepageData } from '@/interfaces/util'
import type { Impianto } from '@/interfaces/impianto'
import type { Cliente } from '@/interfaces/cliente'


export const useHomeStore = defineStore('home', () => {
  const contrattiScadenza: Ref<Impianto[]> = ref([])
  const garanzieScadenza: Ref<Impianto[]> = ref([])

  const loadData = async () => {
    const response: AxiosResponse<HomepageData> = await axiosRequest(
      'GET',
      '/api/homepage-data',
      undefined,
      undefined,
      true
    )

    contrattiScadenza.value = response.data.contratti
    garanzieScadenza.value = response.data.garanzie

    contrattiScadenza.value.forEach(async item => {
      if (typeof item.cliente == 'string') {
        const response: AxiosResponse<Cliente> = await axiosRequest('GET', item.cliente)
        item.cliente = response.data
      }
    })

    garanzieScadenza.value.forEach(async item => {
      if (typeof item.cliente == 'string') {
        const response: AxiosResponse<Cliente> = await axiosRequest('GET', item.cliente)
        item.cliente = response.data
      }
    })
  }
  loadData()

  return {
    contrattiScadenza,
    garanzieScadenza,
    loadData
  }
})
