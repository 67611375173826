import {defineStore} from "pinia";
import {ref} from "vue";
import type {Ref} from "vue";
import {useToast} from "vue-toastification";
import axios from "axios";
import {useRouter} from "vue-router";

export const useAuthStore = defineStore('auth', () => {
    const toast = useToast()

    const loginData: Ref<{ username: string | null, password: string | null }> = ref({username: null, password: null})

    const jwt: Ref<string | null> = ref(localStorage.getItem('jwt'));
    const refreshToken: Ref<string | null> = ref(localStorage.getItem('refreshToken'))

    const router = useRouter()

    let interval: number | undefined = undefined

    const payload: Ref<any> = ref(null)

    const callRefreshToken = async () => {
        try {
            const result = await axios.request({
                baseURL: import.meta.env.VITE_API_URL,
                method: 'post',
                url: '/api/token/refresh',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                timeout: 2000,
                data: {
                    refresh_token: refreshToken.value
                }
            })

            jwt.value = result.data.token
            refreshToken.value = result.data.refresh_token
            localStorage.setItem('jwt', result.data.token)
            localStorage.setItem('refreshToken', result.data.refresh_token)
            // const payl = useJwt(result.data.token)
            // payload.value = payl.payload.value
        } catch (error: any) {
            console.log("refresh token error", error);
            await logout()
            await router.push({name: 'login'})
        }
    }

    const login = async () => {
        if (!loginData.value.username || !loginData.value.password) {
            return;
        }

        try {
            const result = await axios.request({
                baseURL: import.meta.env.VITE_API_URL,
                method: 'post',
                url: '/api/login',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/ld+json"
                },
                timeout: 2000,
                data: loginData.value
            })

            jwt.value = result.data.token
            refreshToken.value = result.data.refresh_token
            localStorage.setItem('jwt', result.data.token)
            localStorage.setItem('refreshToken', result.data.refresh_token)

            // const payl = useJwt(result.data.token)
            // payload.value = payl.payload.value

            interval = setInterval(callRefreshToken, 120000);

            return true;
        } catch (error: any) {
            console.log(error);
            toast.error('Si è verificato un errore, si prega di riprovare più tardi')
            return false;
        }
    }

    const isLoggedIn = () => {
        return jwt.value !== null
    }

    const logout = async () => {
        if (!jwt.value) {
            return
        }

        clearInterval(interval)

        jwt.value = null
        refreshToken.value = null
        localStorage.removeItem('jwt')
        localStorage.removeItem('refreshToken')

        try {
            const result = await axios.request({
                baseURL: import.meta.env.VITE_API_URL,
                method: 'post',
                url: '/api/token/invalidate',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/ld+json"
                },
                timeout: 2000,
                data: {
                    refresh_token: refreshToken.value
                }
            })
        } catch(error) {
            console.log(error);
        }

        return true
    }

    if (refreshToken.value) {
        interval = setInterval(callRefreshToken, 120000);
        callRefreshToken()
    }

    return {
        loginData,
        login,
        jwt,
        isLoggedIn,
        logout,
        payload
    }
})