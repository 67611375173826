import moment from "moment/moment";

export const formatDate = (value: Date) => {
    if (!value) {
        return value
    }

    return moment(value).format('DD/MM/YYYY')
}

export const formatDateTime = (value: Date) => {
    if (!value) {
        return value
    }

    return moment(value).format('DD/MM/YYYY HH:mm:ss')
}

export function dataURLtoFile(dataurl: string, filename: string) {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[arr.length - 1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type: mime});
}