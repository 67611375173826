<script setup lang="ts">
import {useHomeStore} from "@/stores/home";
import ListTableContratti from "@/components/ListTableContratti.vue";

const store = useHomeStore()
</script>

<template>
  <div class="space-y-4">
    <ListTableContratti title="Garanzie in scadenza" :impianti="store.garanzieScadenza" />

    <ListTableContratti title="Contratti in scadenza" :impianti="store.contrattiScadenza" />
  </div>
</template>
