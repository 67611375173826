<script setup lang="ts">
import {useAuthStore} from "@/stores/auth";
import {useRouter} from "vue-router";

const store = useAuthStore()

const router = useRouter()

const login = () => {
  store.login()
      .then(result => {
        if (result) {
          router.push({name: 'home'})
        }
      })
}
</script>

<template>
  <div class="max-w-lg mx-auto">
    <FormKit
        type="form"
        v-model="store.loginData"
        submit-label="Accedi"
        @submit="login"
        :submit-attrs="{
              wrapperClass: 'w-full items-center justify-center flex',
            }"
    >
      <FormKit
          type="email"
          name="username"
          title="Email"
          label="Email"
          prefix-icon="email"
          placeholder="Email"
          validation="required|email"
          class="w-full"
      />
      <FormKit
          type="password"
          name="password"
          title="Password"
          label="Password"
          prefix-icon="password"
          placeholder="Password"
          validation="required"
      />
    </FormKit>
  </div>
</template>