import {useAuthStore} from "@/stores/auth";
import axios, { type AxiosHeaderValue, type AxiosRequestConfig } from 'axios'

export const axiosRequest = async (
  method: string,
  url: string,
  data?: object,
  contentType?: string,
  longTimeout: boolean = false,
  config: AxiosRequestConfig = {}
) => {
    const authStore = useAuthStore()

    if (!contentType) {
        contentType = "application/ld+json"
    }

    const headers: { Accept: AxiosHeaderValue; "Content-Type": AxiosHeaderValue; Authorization?: AxiosHeaderValue; } = {
        "Content-Type": contentType,
        "Accept": "application/ld+json"
    }

    if (authStore.jwt) {
        headers.Authorization = `Bearer ${authStore.jwt}`
    }

    const baseConfig: AxiosRequestConfig = {
        baseURL: import.meta.env.VITE_API_URL,
        method,
        url,
        headers,
        timeout: longTimeout ? 60000 : 5000,
        data
    }
    const requestConfig: AxiosRequestConfig = {...baseConfig, ...config}

    return axios.request(requestConfig)
}
